import React from "react"

import SEO from "../components/seo/seo"
import { StaticImage } from 'gatsby-plugin-image'
const ServicesPage = () => (
  <>
    <SEO title="Our services" />
    <section className="section services-intro">
      <div className="container big">
        <h3 className="title">Our services</h3>
        <p className="desc small">There are few things in life which words cannot express. They can be only experienced. Clear communication, adherence to timelines, prompt support, providing the most optimal & relevant technology to resolve a problem, attention to detail, empathetic and accommodative approach to client’s suggestions, respect for client’s budget constraints and lastly the fine balance of form and substance that is so essential to any project’s appeal and success. These are hallmarks of our collaboration with our clients which differentiates us from the crowd.</p>
      </div>
    </section>
    <section className="section service" id="ideation-and-mvp">
      <div className="container big">
        <h3 className="title" title="Ideation and MVP">Ideation and MVP</h3>
        <p className="desc small">
          The journey from concept to market is a long one and riddled with many obstacles. We help startups to define and devise a plan to get MVP quickly out in the market. Our process consists of the following stages.
        </p>
        <div className="steps">
          <div className="step">
            <div className="step-img">
              <StaticImage
                src="./../images/services/idea-validation.svg"
                height={180}
                quality={95}
                transformOptions={{ fit: "contain" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                alt="idea validation and refinement"
              />
            </div>
            <div className="step-title">Idea validation &amp; <br/>refinement</div>
            <div className="step-desc">Process of testing and validating your idea prior to launching your business name, tagline, product, service, website etc.</div>
          </div>
          <div className="step">
            <div className="step-img">
              <StaticImage
                src="./../images/services/planning.svg"
                height={180}
                quality={95}
                transformOptions={{ fit: "contain" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                alt="Analysis and planning"
              />
            </div>
            <div className="step-title">Analysis &amp; <br/>planning</div>
            <div className="step-desc">
              Discipline combines in-depth analysis of both operational and financial data to help align business processes and strategies with financial goals.
            </div>
          </div>
          <div className="step">
            <div className="step-img">
              <StaticImage
                src="./../images/services/techstack.svg"
                height={180}
                quality={95}
                transformOptions={{ fit: "contain" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                alt="Understanding technology stack"
              />
            </div>
            <div className="step-title">Understanding <br/>technology stack</div>
            <div className="step-desc">
              Identifying the set of softwares that provides the infrastructure for your business and MVP.
            </div>
          </div>
          <div className="step">
            <div className="step-img">
              <StaticImage
                src="./../images/services/mockup.svg"
                height={180}
                quality={95}
                transformOptions={{ fit: "contain" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                alt="Mockup creation"
              />
            </div>
            <div className="step-title">Mockup <br/>creation</div>
            <div className="step-desc">
              Create model used for teaching, demonstration, design evaluation, promotion, and other purposes.
            </div>
          </div>
          <div className="step">
            <div className="step-img">
              <StaticImage
                src="./../images/services/roadmap.svg"
                height={180}
                quality={95}
                transformOptions={{ fit: "contain" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                alt="Roadmap for MVP"
              />
            </div>
            <div className="step-title">Roadmap <br/>for MVP</div>
            <div className="step-desc">
              A version of a new product which allows a team a collect the maximum amount of validated learnings about customers with the least effort.
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section service" id="ux-and-ui">
      <div className="container big">
        <h3 className="title" title="UX and UI Design">UX and UI Design</h3>
        <p className="desc small">
          We believe a UI/UX is more than just having an elegant design. It is your brand’s first point of connect with your customer. So the UI/UX must not only spark interest, it must also convey the Brand Values and drive direct association of the brand with the customer. To ensure this, we follow a DSDI process.
        </p>
        <div className="steps">
          <div className="step">
            <div className="step-img">
              <StaticImage
                src="./../images/services/discover.svg"
                height={180}
                quality={95}
                transformOptions={{ fit: "contain" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                alt="Discover"
              />
            </div>
            <div className="step-title">Discover</div>
            <div className="step-desc">Dig deep, gain an understanding of the business and user's needs and behaviour.</div>
          </div>
          <div className="step">
            <div className="step-img">
              <StaticImage
                src="./../images/services/strategize.svg"
                height={180}
                quality={95}
                transformOptions={{ fit: "contain" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                alt="Strategize"
              />
            </div>
            <div className="step-title">Strategize</div>
            <div className="step-desc">Uncover breakthrough insights and formulate a strategy to achieve set objectives.</div>
          </div>
          <div className="step">
            <div className="step-img">
              <StaticImage
                src="./../images/services/design.svg"
                height={180}
                quality={95}
                transformOptions={{ fit: "contain" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                alt="Design"
              />
            </div>
            <div className="step-title">Design</div>
            <div className="step-desc">Materialises what's visualized - Craft engaging designs that are themed appropriately.</div>
          </div>
          <div className="step">
            <div className="step-img">
              <StaticImage
                src="./../images/services/iterate.svg"
                height={180}
                quality={95}
                transformOptions={{ fit: "contain" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                alt="Iterate"
              />
            </div>
            <div className="step-title">Iterate</div>
            <div className="step-desc">Dig deep, gain an understanding of the business and user's needs and behaviour.</div>
          </div>
        </div>
      </div>
    </section>
    <section className="section service" id="web-development">
      <div className="container big">
        <h3 className="title" title="Web development">Web development</h3>
        <p className="desc small">
          An effective customised website caters to your individual needs, spreads brand awareness, enhances your credibility, and generates new leads. <br/>All of it begins with a Website Strategy, Information Architecture, and User Experience/User Interface Design. We then transition over to Front-End & Back-End Web Development, Custom Plugin Creation, Security &amp; Quality Assurance, Website Launch, Services &amp; Training
        </p>
      </div>
    </section>
    <section className="section service" id="app-development">
      <div className="container big">
        <h3 className="title" title="App development">App development</h3>
        <p className="desc small">
          Empower your business with Custom App Development. We help you engage, acquire and retain your users through intuitive interface and convenient functionalities. Through our organised system of managing projects and lean structure to maximise creativity, we ensure that your project is delivered always on time, budget and quality.
        </p>
      </div>
    </section>
    <section className="section service" id="maintenance">
      <div className="container big">
        <h3 className="title" title="Maintenance">Maintenance</h3>
        <p className="desc small">
          An app or website’s life cycle faces many changes in external factors like : Operating systems receiving updates, security patches introducing new restrictions, underlying frameworks going for an over haul and third party plugins and services becoming obsolete. In midst of these external factors, an app or a website must function flawlessly to avoid any disruption to business. We ensure this through our proactive approach to updates and maintenance. Besides, our team is just a call away to support you for anything.
        </p>
        <div className="steps">
          <div className="step">
            <div className="step-img">
              <StaticImage
                src="./../images/services/reactive.svg"
                height={180}
                quality={95}
                transformOptions={{ fit: "contain" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                alt="Reactive maintenance"
              />
            </div>
            <div className="step-title">Reactive</div>
            <div className="step-desc">Fix when broken</div>
          </div>
          <div className="step">
            <div className="step-img">
              <StaticImage
                src="./../images/services/planned.svg"
                height={180}
                quality={95}
                transformOptions={{ fit: "contain" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                alt="Planned maintenance"
              />
            </div>
            <div className="step-title">Planned</div>
            <div className="step-desc">Scheduled maintenance activities</div>
          </div>
          <div className="step">
            <div className="step-img">
              <StaticImage
                src="./../images/services/proactive.svg"
                height={180}
                quality={95}
                transformOptions={{ fit: "contain" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                alt="Proactive maintenance"
              />
            </div>
            <div className="step-title">Proactive</div>
            <div className="step-desc">Defect elimination to improve performance</div>
          </div>
          <div className="step">
            <div className="step-img">
              <StaticImage
                src="./../images/services/predictive.svg"
                height={180}
                quality={95}
                transformOptions={{ fit: "contain" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                alt="Predictive maintenance"
              />
            </div>
            <div className="step-title">Predictive</div>
            <div className="step-desc">Advanced analytics and sensing data to predict system reliability</div>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default ServicesPage
